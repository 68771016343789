<template>
  <div>
    <div class="full-loading"
         v-if="loading">
      <van-loading style="margin-bottom: 10px" />
      请求中
    </div>

    <div class="header">
      <p class="h1">市场用户采集表</p>
      <el-button size="small"
                 type="primary"
                 @click="toFormList"
                 plain>查看统计</el-button>
    </div>

    <div class="form">
      <el-form :model="formData"
               :rules="rules"
               ref="ruleForm"
               class="demo-ruleForm">
        <el-form-item label="档口老板手机号"
                      prop="phone">
          <el-input v-model="formData.phone"
                    placeholder="手机号"
                    maxlength="11"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="类型"
                      prop="market_type">
          <el-radio-group v-model="formData.market_type"
                          style="width: 100%"
                          @change="changeRadio">
            <el-radio label="0">批发市场</el-radio>
            <el-radio label="1">原产地</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="
            (formData.market_type === '0' ? '批发市场' : '原产地') + '选择'
          "
                      prop="market_id">
          <el-select ref="select"
                     style="width: 100%"
                     v-model="formData.market_id"
                     filterable
                     remote
                     @hook:mounted="cancalReadOnly"
                     @visible-change="cancalReadOnly"
                     :placeholder="
              '搜索' + (formData.market_type === '0' ? '批发市场' : '原产地')
            "
                     :remote-method="remoteMethod"
                     :loading="loadingMarket"
                     @change="changeSelect"
                     clearable>
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="档口名称"
                      prop="org_name">
          <el-input v-model="formData.org_name"
                    placeholder="档口名称"
                    :maxlength="50"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="具体地址"
                      prop="address">
          <el-input v-model="formData.address"
                    placeholder="具体地址"
                    :maxlength="50"></el-input>
        </el-form-item>

        <el-form-item label="门店形象照"
                      prop="business_license">
          <el-upload class="avatar-uploader"
                     action=""
                     :show-file-list="false"
                     :before-upload="beforeAvatarUpload"
                     :http-request="uploadCover">
            <div class="uploader"
                 style="height: 100px; width: 100px">
              <i class="el-icon-remove"
                 v-if="formData.business_license"
                 @click="formData.business_license = ''"></i>
              <img v-if="formData.business_license"
                   :src="formData.business_license"
                   class="avatar" />
              <i v-else
                 class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="商品列表"
                      prop="product_list">
          <div class="product-box">
            <div class="product-item"
                 v-for="(item, index) in formData.product_list"
                 :key="index">
              <div class="product-item-input">
                <span class="must">*</span> 商品名称
                <el-input v-if="editProductIndex === index"
                          placeholder="商品名称"
                          v-model="item.product_name"
                          :maxlength="30"
                          clearable></el-input>
                <div v-else>{{ item.product_name }}</div>
              </div>
              <div class="product-item-input">
                <span class="must">*</span> 分类
                <div v-if="editProductIndex === index" @click="openClassifyPopup">
                  <el-input disabled
                          placeholder="商品分类" v-model="item.classify_name">
                    <template slot="append">
                      <span style="color: #299ffe;">选择</span>
                    </template>
                  </el-input>
                </div>
                <div v-else>{{ item.classify_name }}</div>
              </div>
              <div class="product-item-input">
                <span class="must">*</span>价格
                <el-input placeholder="价格"
                          v-model="item.price"
                          v-if="editProductIndex === index"
                          type="number"
                          clearable></el-input>
                <div v-else>{{ item.price }}</div>
              </div>

              <div class="product-item-input">
                <span class="must">*</span>起批量
                <el-input placeholder="起批量"
                          v-model="item.qty"
                          v-if="editProductIndex === index"
                          :maxlength="10"
                          clearable></el-input>
                <div v-else>{{ item.qty }}</div>
              </div>

              <div class="product-item-input">
                <span class="must">*</span>单位
                <el-input placeholder="单位"
                          v-model="item.unit"
                          v-if="editProductIndex === index"
                          :maxlength="10"
                          clearable></el-input>
                <div v-else>{{ item.unit }}</div>
              </div>

              <span class="must">*</span> 商品图片
              <div class="product-imgs">
                <div style="position: relative"
                     v-for="(img, i) in item.imgs"
                     :key="i">
                  <i v-if="editProductIndex === index"
                     class="el-icon-remove"
                     @click="removeProductImage(index, i)"></i>

                  <img :src="img"
                       class="product-img" />
                </div>
                <el-upload v-if="item.imgs.length < 3 && editProductIndex === index"
                           action=""
                           :show-file-list="false"
                           :before-upload="beforeAvatarUpload"
                           :http-request="(opt) => uploadProduct(opt, index)">
                  <div class="uploader product-img">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </el-upload>
              </div>

              <div class="product-footer"
                   v-if="editProductIndex === index">
                <el-button v-if="formData.product_list.length > 1"
                           type="text"
                           @click="removeProduct(index)"
                           style="color: red">删除</el-button>
                <el-button type="text"
                           @click="saveProductItem(item)">保存</el-button>
              </div>

              <div class="product-footer"
                   v-else>
                <el-button type="text"
                           @click="editProduct(index)">编辑</el-button>
                <el-button type="text"
                           @click="removeProduct(index)"
                           style="color: red">删除</el-button>
              </div>
            </div>
            <div class="product-footer">
              <el-button type="text"
                         @click="addProduct">添加商品</el-button>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="提交人"
                      prop="create_by">
          <el-input v-model="formData.create_by"
                    placeholder="提交人"
                    :maxlength="10"
                    clearable></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="footer">
      <el-button class="footer-button"
                 type="primary"
                 @click="onSubmit">提交</el-button>
    </div>
    <classify-popup  ref="classifyPopupRef" @handleClassify="setClassify"/>
  </div>
</template>

<script>
import { applyMarket, searchMarket } from '@/api/form'
// import { upload } from '@/api/public'
import classifyPopup from './classifyPopup.vue'

export default {
  components: {
    classifyPopup
  },
  data () {
    return {
      loading: false,
      formData: {
        market_id: '',
        market_name: '',
        market_type: '0', // 市场类型 0:专业市场 1:原产地
        org_name: '',
        phone: '',
        address: '',
        business_license: '',
        create_by: '',
        product_list: [
          {
            product_name: '', // 不能为空
            imgs: [], // 最少1张最多3张
            price: '', // 价格
            qty: '', // 起批数量
            unit: '',
            classify_code: '', // 三级分类编码
            classify_name: '' // 分类名称
            // classify_dict: '' // 商品分类
          }
        ]
      },
      rules: {
        phone: [
          { required: true, message: '请输入档口手机号码', trigger: 'blur' },
          { min: 11, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        market_type: [{ required: true, trigger: 'blur' }],
        market_id: [
          { required: true, message: '请选择市场或原产地', trigger: 'blur' }
        ],
        org_name: [
          { required: true, message: '请输入档口名称', trigger: 'blur' }
        ],
        business_license: [
          { required: true, message: '请上传门店照片', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        product_list: [
          { required: true, message: '请填写商品', trigger: 'blur' }
        ],
        create_by: [
          { required: true, message: '请填写提交人', trigger: 'blur' }
        ]
      },
      loadingMarket: false,
      options: [],
      editProductIndex: 0
    }
  },
  created () {
    document.title = '市场用户采集表'
    this.getList()
  },
  methods: {
    openClassifyPopup () {
      this.$refs.classifyPopupRef.open()
    },
    setClassify (classify) {
      this.formData.product_list[this.editProductIndex].classify_name = `${classify.c1_name}/${classify.c2_name}/${classify.c3_name}`
      this.formData.product_list[this.editProductIndex].classify_code = classify.c3_code
      // console.log(`${classify.c1_name}/${classify.c2_name}/${classify.c3_name}`)
    },
    cancalReadOnly () {
      this.$nextTick(() => {
        const { select } = this.$refs
        const input = select.$el.querySelector('.el-input__inner')
        input.removeAttribute('readonly')
      })
    },
    addProduct () {
      this.formData.product_list.push({
        product_name: '', // 不能为空
        imgs: [], // 最少1张最多3张
        price: '', // 价格
        qty: '', // 起批数量
        unit: '',
        classify_name: '',
        classify_code: ''
        // classify_dict: '' // 商品分类
      })

      this.editProductIndex = this.formData.product_list.length - 1
    },
    saveProductItem (item) {
      if (!item.product_name) {
        return this.$toast('请填写商品名称')
      }

      if (!item.price) {
        return this.$toast('请填写商品价格')
      }

      if (parseFloat(item.price) < 0) {
        return this.$toast('请填写正确的商品价格')
      }

      if (!item.classify_name) {
        return this.$toast('请选择商品分类')
      }

      if (!item.qty) {
        return this.$toast('请填写商品起批量')
      }

      if (!item.unit) {
        return this.$toast('请填写计量单位')
      }

      if (!item.imgs.length) {
        return this.$toast('请上传商品图片')
      }

      this.editProductIndex = -1
    },
    editProduct (index) {
      if (this.editProductIndex !== -1) {
        return this.$toast('请先保存编辑中的商品')
      }
      this.editProductIndex = index
    },
    removeProduct (index) {
      this.$dialog
        .confirm({
          title: '提示',
          message: '确认删除该商品？'
        })
        .then(() => {
          this.formData.product_list.splice(index, 1)
        })
    },
    onSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            this.formData.product_list.some((el) => {
              return (
                !el.product_name ||
                !el.imgs.length ||
                !el.price ||
                !el.unit ||
                !el.qty ||
                !el.classify_name
              )
            })
          ) {
            return this.$toast('请完善商品数据')
          }

          if (
            this.formData.product_list.some((el) => {
              return parseFloat(el.price) < 0
            })) {
            return this.$toast('请填写正确的商品价格')
          }

          this.loading = true
          applyMarket(this.formData)
            .then((res) => {
              this.loading = false

              this.$dialog
                .alert({
                  confirmButtonColor: '#07c160',
                  message: '提交成功',
                  confirmButtonText: '继续填表'
                })
                .then(() => {
                  this.formData = {
                    market_id: this.formData.market_id,
                    market_name: this.formData.market_name,
                    market_type: this.formData.market_type, // 市场类型 0:专业市场 1:原产地
                    org_name: '',
                    phone: '',
                    address: '',
                    business_license: '',
                    create_by: this.formData.create_by,
                    product_list: [
                      {
                        product_name: '', // 不能为空
                        imgs: [], // 最少1张最多3张
                        price: '', // 价格
                        qty: '', // 起批数量
                        unit: '',
                        classify_name: '', // 商品分类
                        classify_code: ''
                      }
                    ]
                  }

                  this.editProductIndex = 0
                })
            })
            .catch((err) => {
              this.loading = false

              console.log(err, 'err')
              this.$toast(err.msg || '请求失败')
            })
        } else {
          this.$toast('请检查表单')
        }
      })
    },
    remoteMethod (query) {
      this.loadingMarket = true
      this.getList(query)
    },
    getList (query) {
      searchMarket({
        page: 1,
        per_page: 20,
        market_name: query || '',
        market_type: this.formData.market_type
      })
        .then((res) => {
          console.log(res, 'searchMarket')
          this.options = res.data.data.list.map((el) => {
            return {
              label: el.market_name,
              value: el.market_id
            }
          })
          this.loadingMarket = false
        })
        .catch(() => {
          this.loadingMarket = false
        })
    },
    changeSelect (value) {
      const item = this.options.find((el) => el.value === value)
      if (item) {
        this.formData.market_name = item.label
      }
    },
    beforeAvatarUpload (file) {
      if (file.size > 20 * 1024 * 1024) {
        this.$toast('上传文件不能超过20M大小')
        return false
      }
      return true
    },
    uploadProduct (options, index) {
      this.loading = true
      this.$uploadObs(options.file).then((url) => {
        this.loading = false
        this.formData.product_list[index].imgs.push(url + '?x-oss-process=image/resize,l_600/quality,q_80')
      })
    },
    removeProductImage (index, i) {
      this.formData.product_list[index].imgs.splice(i, 1)
    },
    uploadCover (options) {
      this.loading = true
      this.$uploadObs(options.file).then((url) => {
        this.loading = false
        this.formData.business_license = url + '?x-oss-process=image/resize,l_600/quality,q_80'
      })
    },
    changeRadio () {
      this.formData.market_name = ''
      this.formData.market_id = ''
      this.getList()
    },
    toFormList () {
      this.$router.push({
        name: 'formList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}
.h1 {
  padding: 30px 15px;
  font-size: 18px;
  font-weight: 500;
}
.form {
  padding: 0 15px;
  padding-bottom: 60px;
}
.form-label {
  font-weight: 500;
  padding: 10px;
  padding-bottom: 5px;
}

.avatar-uploader {
  width: 100%;
  overflow: hidden;
}

.uploader {
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
  display: block;
}

.product-box {
  width: 100%;
  overflow: hidden;

  .product-item {
    background: #f3f3f3;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;

    .product-item-input {
      padding: 5px 0;
    }
  }

  .product-imgs {
    display: flex;
    align-items: center;
    position: relative;
  }

  .product-img {
    margin-right: 2vw;
    width: 27vw;
    height: 27vw;
  }

  .product-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
  }
}

.el-icon-remove {
  font-size: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 10px;
  background: #fff;
  z-index: 9;
  box-shadow: 0 -10px 30px 0px #eee;

  .footer-button {
    width: 100%;
    height: 40px;
  }
}

.must {
  color: red;
}

.full-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 999;
  color: #fff;
}
</style>
