<template>
 <!-- 分类选择-弹出层 -->
  <van-popup v-model="show" closeable round position="bottom" @closed="closePopup" :style="{ height: '582px' }">
    <div class="popup-box">
      <p class="title">分类选择</p>
      <!-- 搜索区 -->
      <form action="/" class="border-b">
        <van-search
          v-model="searchClassifyValue"
          show-action
          placeholder="搜索"
          @search="onSearch"
          @focus="onSearchFocus"
          @input="onSearchInput"
        >
          <template #action>
            <div @click="onSearch" class="search-btn">搜索</div>
          </template>
        </van-search>
      </form>
      <!-- 内容区: 1-左右分类, 2-常用分类[取消显示], 3-分类列 -->
      <div v-if="popupType === 1" v-loading="loading" class="cat-box">
        <div class="left">
          <div @click="setLeftIndex(index)" class="l-item" :class="{active:index == leftIndex}" v-for="(item,index) in classifyList" :key="index">
            <span> {{item.label}} </span>
          </div>
        </div>
        <div v-if="classifyItem" class="right">
          <div v-for="(item, index) in classifyItem.children" :key="index" class="r-item">
            <div class="r-title" @click="item.isShow= !item.isShow">
              <div class="p-l-12">{{ item.label }}</div>
              <i class="el-icon-arrow-down r-icon" v-if="item.isShow"></i>
              <i class="el-icon-arrow-up r-icon" v-if="!item.isShow"></i>
            </div>
            <div v-if="item.isShow" class="wrap">
              <div @click="setCommonClass(item, _item)" :class="{'w-item': true, 'active-item': activeClassifyItem.c3_code === _item.id}" v-for="(_item, _index) in item.children" :key="_index">
                <span>{{_item.label}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="popupType === 3" class="search-box">
        <van-list style="width: 100%"
              :error.sync="error"
              v-model="searchLoading"
              :finished="finished"
              :finished-text="finishedText"
              @load="onLoad">
          <ul>
            <li v-for="(item, index) in searchClassifyList"  :key="index" :class="{'active-li': activeClassifyItem.c3_code === item.c3_code}" @click="chooseItem(item)" >
              {{item.c2_name}} - {{ item.c3_name }}
              <span>{{ item.c1_name }}</span>
            </li>
          </ul>
        </van-list>
      </div>
      <div v-else class="common-search">
        <p class="tit">常用分类</p>
        <ul>
          <li v-for="(item, index) in commonClassifyList" :key="index" @click="chooseCommonItem(item)" :class="{'active-li': activeClassifyItem.c3_code === item.code}">{{ item.name }}</li>
        </ul>
      </div>
      <!-- 按钮 -->
      <div class="popup-footer">
        <el-button class="footer-button"
                type="primary"
                @click="sendClassify">确定</el-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getClassifyList, getDesignatedClassifyList } from '@/api/form'

export default {
  props: {
    value: { // 开关
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: false,
      searchLoading: false,
      finished: false,
      finishedText: '没有更多数据',
      // =====
      show: false, // 弹出层按钮
      loading: false, // 分类加载
      popupType: 1, // 1-分类，2-常用分类，3-分类列
      searchClassifyValue: '', // 搜索分类-输入框内容
      leftIndex: 0, // 左边激活项
      activeClassifyItem: {}, // 右边选中项
      classifyList: [], // 总分类
      searchClassifyList: [], // 搜索出的分类
      commonClassifyList: [], // 常用的分类
      page: 0 // 分页
    }
  },
  created () {},
  computed: {
    classifyItem () {
      return this.classifyList[this.leftIndex]
    }
  },
  methods: {
    // 加载更多
    onLoad () {
      this.searchLoading = true
      if (this.error) {
        this.getDesignatedClassify()
      } else {
        this.page += 1
        this.getDesignatedClassify()
      }
    },
    // 打开弹窗
    open () {
      this.show = true
      this.getProductClassify()
    },
    // 关闭弹窗
    closePopup () {
      this.popupType = 1
      this.searchClassifyValue = ''
      this.leftIndex = 0
      this.activeClassifyItem = {}
      this.classifyList = []
      this.searchClassifyList = []
      this.commonClassifyList = []
      this.page = 0
    },
    // 搜索框聚焦
    onSearchFocus (event) {
    //   if (event.target.value === '') {
    //     this.popupType = 2
    //   }
    },
    // 输入框内容改变
    onSearchInput (value) {
      if (value) {
        this.popupType = 3
        // 防抖
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.getDesignatedClassify(true)
        }, 1000)
      } else {
        // this.popupType = 2
      }
    },
    // 搜索
    async onSearch () {
      if (this.searchClassifyValue !== '') {
        this.popupType = 3
        this.finished = false
        this.loading = true
        this.page = 1
        await this.getDesignatedClassify(true)
      }
    },
    // 选中-左边某项
    setLeftIndex (index) {
      this.leftIndex = index
    },
    // 选中-右边某项
    setCommonClass (item, _item) {
      const obj = {
        c1_code: this.classifyList[this.leftIndex].id, // 左边id
        c1_name: this.classifyList[this.leftIndex].label, // 左边label
        c2_code: item.id, // 父id
        c2_name: item.label, // 父label
        c3_code: _item.id, // 自id
        c3_name: _item.label // 自label
      }
      this.activeClassifyItem = obj
    },
    // 选中-搜索的某项
    chooseItem (item) {
      this.activeClassifyItem = item
    },
    // 选中-常用某项
    chooseCommonItem (item) {
      const obj = {
        c1_code: item.c1_code,
        c1_name: item.c1_name,
        c2_code: item.c2_code,
        c2_name: item.c2_name,
        c3_code: item.code,
        c3_name: item.name
      }
      this.activeClassifyItem = obj
    },
    // 获取商品分类
    getProductClassify () {
      this.loading = true
      getClassifyList({}).then((res) => {
        this.loading = false
        const newRes = res.data.data.map(item => {
          item.children = item.children.map(_item => {
            _item.isShow = true
            return _item
          })
          return item
        })
        this.classifyList = newRes
      }).catch(() => {
        this.loading = false
      })
    },
    // 根据关键字获取商品分类
    getDesignatedClassify (isReset) {
      getDesignatedClassifyList({
        page: this.page,
        per_page: 20,
        keyword: this.searchClassifyValue.trim()
      }).then((res) => {
        this.searchLoading = false
        if (res.data.data.list.length < 20) {
          this.finished = true
        }
        this.searchClassifyList = isReset ? res.data.data.list : [...this.searchClassifyList, ...res.data.data.list]
        if (this.page === 1 && res.data.data.list.length === 0) {
          this.finishedText = '暂无数据'
        } else {
          this.finishedText = '没有更多数据'
        }
      }).catch(() => {})
    },
    // 提交
    sendClassify () {
      if (!Object.keys(this.activeClassifyItem).length) {
        this.$toast('请选择商品分类')
        return
      }
      this.show = false
      this.$emit('handleClassify', this.activeClassifyItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.p-l-12 {
  padding-left: 12px;
}
.van-popup--bottom.van-popup--round {
  border-radius: 20px 20px 0 0;
}
.popup-box {
  background: #f3f4f6;
  box-sizing: border-box;
  height: 100%;

  ::v-deep .el-button--primary {
    color: #FFF;
    background-color: #00a0ac;
    border-color: #00a0ac;
  }
  .title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    padding: 17px 26px 0px 26px;
    background-color: white;
  }

  .search-btn {
    color: #00a0ac;
    font-size: 15px;
    padding: 0 8px;
  }

  .popup-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 8px;
    background: #fff;
    z-index: 9;
    box-shadow: 0 -10px 30px 0px #eee;

    .footer-button {
      font-size: 16px;
      width: 100%;
      height: 40px;
    }
  }
}

.border-b{
  border-bottom: 1px solid #dddddd;
}
.cat-box {
  background: #f3f4f6;
  display: flex;

  .left {
    width: 80px;
    height: 430px;
    overflow-y: scroll;
    background: #fff;

    .l-item {
      box-sizing: border-box;
      position: relative;
      height: 64px;
      padding: 22px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px;
        color: #202122;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 20px;
        text-align: center;
      }
    }
    .active {
      background: #f3f4f6;
    }
    .active::before {
      position: absolute;
      left: 0px;
      top: 0px;
      content: '';
      background: #00a0ac;
      border-radius: 0px 5px 5px 0px;
      width: 2px;
      height: 64px;
    }
  }
  .right {
    flex: 1;
    height: 430px;
    overflow-y: scroll;
    padding: 10px 16px;
    box-sizing: border-box;

    .r-title {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .r-icon {
      padding-right: 12px;
    }

    .r-item {
      border-radius: 5px;
      background: #fff;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .wrap {
      width: 100%;
      display: flex;
      flex-flow:row wrap;
      align-items: center;
      align-content: space-around;
      justify-content: flex-start;
      height: auto;
      transition: 0.3s;
      box-sizing: border-box;
      padding: 0 12px;
      .w-item {
        font-size: 14px;
        box-sizing: border-box;
        width: 70px;
        height: 30px;
        line-height: 34px;
        margin-bottom: 8px;
        margin-right: 4px;
        padding: 0 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        background: #fff;
        border: 1px solid #c4c4c4;
        border-radius: 2px;
        color: #333;
      }

      .active-item {
        color: #008af9;
        border: 1px solid #008af9;
      }
    }

  }
}

.search-box {
  background: #f3f4f6;
  height: 430px;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  ul {
    background-color: white;
    padding: 0 16px;
    li {
      background-color: white;
      height: 50px;
      line-height: 50px;
      padding: 0 16px;
      border-bottom: 1px solid #dddddd;
      font-size: 15px;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #888888;
        float: right;
      }
    }
  }
}

.common-search {
  box-sizing: border-box;
  padding: 16px;
  background: #f3f4f6;
  p.tit {
    font-size: 14px;
    color: #999;
  }
  ul {
    width: 100%;
    overflow: hidden;
    li {
      font-size: 14px;
      float: left;
      margin: 8px 8px 0 0;
      background-color: white;
      height: 32px;
      line-height: 32px;
      padding: 0 16px;
    }
  }
}

.active-li {
  color: #008af9;
}
.t-c {
  text-align: center;
}
.no-more {
  text-align: center;
  color: #888888;
}
</style>
