<template>
  <div>
    <div class="wechat-tips" v-if="isWechat">
      <img
        src="@/assets/images/download/825.png"
        alt="下载页背景图"
        class="new-market-bg"
      />
    </div>

    <!-- 头部 -->
    <div class="navbar">
      <span class="navbar_title">宝秤新集贸-下载中心</span>
    </div>

    <div class="content">
      <img src="@/assets/images/download/Group2818.png" alt="下载中心背景图" />
      <div class="btn" @click="openApp()">打开宝秤app</div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: '宝秤新集贸-下载中心'
  },
  data () {
    return {
      isWechat: false
    }
  },
  created () {
    this.isWechat = this.$isWechat()
  },
  methods: {
    openApp () {
      // 直接唤醒app
      window.location.href = 'newmarket://'
      // 跳转下载落地页
      setTimeout(() => {
        this.$router.push({
          name: 'Download'
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid rgb(235, 231, 231);
  z-index: 9;
}

.wechat-tips {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
}

.new-market-bg {
  width: 100%;
}

.content {
  img {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.btn {
  position: fixed;
  bottom: 20px;
  left: 14px;
  right: 14px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #00a0ac;
  border-radius: 8px;
  color: #fff;
}
</style>
