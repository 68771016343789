<template>
  <div class="statistics">
    <div style="width:100%;height:180px"></div>
    <div class="box">
      <div class="header">
        <p class="h1">市场用户采集表</p>
        <el-button size="small"
                   type="primary"
                   @click="back"
                   plain>继续填表</el-button>
      </div>
      <div class="from">
        <!-- <van-calendar v-model="show"
                    color="#1989fa" /> -->
        <van-search style="padding:0px;flex:1;margin-right:15px"
                    v-model="params.search_data"
                    placeholder="请输入提交人名称">

        </van-search>
        <template>
          <el-button @click="reset"
                     size="small"
                     type="primary">重置</el-button>
          <el-button @click="onSearch"
                     size="small"
                     type="primary">搜索</el-button>
        </template>

      </div>
      <div class="time">
        <div class="start"
             @click='showStart = true'>
          <div style="color:#606266">
            {{startTimeSrt ? startTimeSrt  : '开始日期'}}
          </div>
        </div>
        <div>至</div>
        <div class="end"
             @click='showEndTime'>
          <div style="color:#606266">
            {{endTimeSrt ? endTimeSrt : '结束日期'}}
          </div>
        </div>
      </div>

    </div>
    <van-list style="padding:0px 15px"
              :error.sync="error"
              v-model="loading"
              :finished="finished"
              offset='50'
              :finished-text="finishedText"
              @load="onLoad">
      <div class="item"
           v-for='(item,index) in list'
           :key='index'>
        <div class="top">
          <div>提交人：{{item.create_by}}</div>
          <div>数量：{{item.num}}</div>
        </div>
        <div class="bottom">
          <div>日期：{{item.create_time}}</div>

        </div>
      </div>
    </van-list>
    <van-popup v-model="showStart"
               position="bottom">
      <van-datetime-picker @cancel='showStart = false'
                           @confirm='confirmStartTime'
                           v-model="startTime"
                           type="date"
                           title="选择开始时间"
                           :max-date="maxDate" />
    </van-popup>
    <van-popup v-model="showEnd"
               position="bottom">
      <van-datetime-picker @cancel='showEnd = false'
                           @confirm='confirmEndTime'
                           v-model="endTime"
                           type="date"
                           title="选择结束时间"
                           :min-date='startTime'
                           :max-date="maxDate" />
    </van-popup>

  </div>

</template>

<script>
import { getStatisticsList } from '@/api/form'
import { Toast } from 'vant'
export default {
  components: {},
  props: {},
  data () {
    return {
      show: false,
      error: false,
      value: '',
      value6: '',
      list: [],
      loading: false,
      finishedText: '没有更多数据',
      finished: false,
      showStart: false,
      showEnd: false,
      startTime: '',
      endTime: '',
      startTimeSrt: '',
      endTimeSrt: '',
      maxDate: new Date(),
      params: {
        page: 0,
        per_page: 20,
        start_time: '', //  开始时间 可空
        end_time: '', //  结束时间 可空
        search_data: ''//  提交人搜索
      }

    }
  },
  watch: {},
  computed: {},
  methods: {
    back () {
      this.$router.back()
    },
    reset () {
      this.params = {
        page: 1,
        per_page: 20,
        start_time: '', //  开始时间 可空
        end_time: '', //  结束时间 可空
        search_data: ''//  提交人搜索
      }
      this.startTimeSrt = ''
      this.endTimeSrt = ''
      this.startTime = ''
      this.endTime = ''
      this.list = []
      this.finished = false
      this.loading = true
      this.startTime = new Date()
      this._getStatisticsList()
    },
    onSearch () {
      if (this.startTimeSrt && !this.endTimeSrt) {
        Toast('请选择结束时间')
        return
      }
      if (this.endTimeSrt && !this.startTimeSrt) {
        Toast('请选择开始时间')
        return
      }
      this.params.page = 1
      this.list = []
      this.finished = false
      this.loading = true
      this._getStatisticsList()
    },
    onLoad () {
      if (this.error) {
        this._getStatisticsList()
      } else {
        this.params.page += 1
        this._getStatisticsList()
      }
    },
    _getStatisticsList () {
      getStatisticsList(this.params).then(res => {
        console.log(res.data.data)
        const data = res.data.data
        setTimeout(() => {
          this.list = [...this.list, ...data.list]
          this.loading = false
          if (data.list.length < 20) {
            this.finished = true
          }
          if (this.params.page === 1 && data.list.length === 0) {
            this.finishedText = '暂无数据'
          } else {
            this.finishedText = '没有更多数据'
          }
        }, 500)
      }).catch(() => {
        this.error = true
      })
    },
    confirmStartTime (val) {
      this.startTimeSrt = this.dateFormat('YYYY-mm-dd', val)
      this.params.start_time = this.dateFormat('YYYYmmdd', val)
      this.endTimeSrt = ''
      this.params.end_time = ''
      this.endTime = ''
      this.showStart = false
    },
    showEndTime () {
      if (!this.startTime) {
        Toast('请先选开始时间')
        return
      }
      this.showEnd = true
    },
    confirmEndTime (val) {
      this.endTimeSrt = this.dateFormat('YYYY-mm-dd', val)
      this.params.end_time = this.dateFormat('YYYYmmdd', val)
      this.showEnd = false
    },
    dateFormat (fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        };
      };
      return fmt
    }
  },
  created () {
    this.params.page = 0
    // this._getStatisticsList()
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
.statistics {
  .box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 180px;
    padding: 0px 15px;
    background: #fff;
    box-sizing: border-box;
    .time {
      width: 100%;
      height: 33px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #dcdfe6;
      align-items: center;
      .start {
        flex: 1;
        text-align: center;
        color: #606266;
      }
      .end {
        flex: 1;
        text-align: center;
        color: #606266;
      }
    }
    .from {
      padding: 10px 0px;
      display: flex;
      justify-content: space-between;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .h1 {
    padding: 25px 0px;
    font-size: 18px;
    font-weight: 500;
  }
  .item {
    padding: 15px 0px;
    border-bottom: 1px solid #dcdfe6;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .bottom {
      display: flex;
    }
  }
}
</style>
